import React, { useEffect } from 'react'
import RedirectMessage from '../components/Redirect';

const url = `https://rewards.vigor.ai`;
const Learn: React.FC<{}> = (props) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.location.replace(url);
    }
  }, []);

  return (
      <RedirectMessage />
  );
};

export default Learn;
